import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SECTIONS } from '../shared/section';
import { CARDS } from '../shared/card';
import { ButtonLinkComponent } from '../shared/button/button-link/button-link.component';
import { LISTS } from '../shared/list';

@Component({
  standalone: true,
  selector: 'lumiron-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgOptimizedImage,
    NgbCarouselModule,
    RouterModule,
    SECTIONS,
    CARDS,
    LISTS,
    ButtonLinkComponent,
  ],
})
export class HomeComponent {
  private readonly meta = inject(Meta);

  constructor(){
    this.meta.addTags([
      {name: 'description', content: 'Bubble Soccer ist das perfekte Erlebnis für Ihr Event. Es garantiert Spass, Teamgeist und eine Menge Action.'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
