import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CARDS } from '../card';
import { SECTIONS } from '../section';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ButtonLinkComponent } from '../button/button-link/button-link.component';

@Component({
  standalone: true,
  selector: 'lumiron-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    ButtonLinkComponent, 
    CARDS, 
    SECTIONS
  ]
})
export class FooterComponent {}
