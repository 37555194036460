import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BehaviorSubject, map, take, tap } from 'rxjs';
import { NavItem } from './nav';
import { ButtonComponent } from '../button/button.component';
import { ButtonLinkComponent } from '../button/button-link/button-link.component';

@Component({
  standalone: true,
  selector: 'lumiron-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, ButtonComponent, ButtonLinkComponent],
})
export class NavComponent {
  public brand = input.required<string>();
  public navItems = input.required<readonly NavItem[]>();

  protected readonly isNavCollapsed$ = new BehaviorSubject<boolean>(true);

  public toggleNav(): void {
    this.isNavCollapsed$
      .pipe(
        take(1),
        map((isNavCollapsed) => !isNavCollapsed),
        tap((isNavCollapsed) => this.isNavCollapsed$.next(isNavCollapsed)),
      )
      .subscribe();
  }
}
