<nav>
  <a class="brand" routerLink="/" (click)="isNavCollapsed$.next(true)">{{
    brand()
  }}</a>

  <div class="items">
    @for (navItem of navItems(); track $index) {
      <a
        class="item"
        [routerLink]="'/' + navItem.link"
        (click)="isNavCollapsed$.next(true)"
        routerLinkActive="active"
        >{{ navItem.label }}</a
      >
    }
  </div>

  <div class="buttons">
    <lumiron-button-link [color]="'secondary'" [link]="'/kontakt'"
      >Kontakt</lumiron-button-link
    >
    <lumiron-button-link [link]="'/buchung'">Online buchen</lumiron-button-link>
  </div>

  <button class="toggler" (click)="toggleNav()">
    <i
      class="bi bi-list"
      [ngClass]="{
        'bi-list': (isNavCollapsed$ | async) === true,
        'bi-x-lg': (isNavCollapsed$ | async) === false,
      }"
    ></i>
  </button>

  <div
    class="mobile-items"
    [ngClass]="{ show: (isNavCollapsed$ | async) === false }"
  >
    @for (navItem of navItems(); track $index) {
      <a
        class="item"
        [routerLink]="'/' + navItem.link"
        (click)="isNavCollapsed$.next(true)"
        routerLinkActive="active"
        >{{ navItem.label }}</a
      >
    }

    <div class="mobile-buttons">
      <lumiron-button-link
        [color]="'secondary'"
        [link]="'/kontakt'"
        (click)="isNavCollapsed$.next(true)"
        >Kontakt</lumiron-button-link
      >
      <lumiron-button-link
        [link]="'/buchung'"
        (click)="isNavCollapsed$.next(true)"
        >Online buchen</lumiron-button-link
      >
    </div>
  </div>
</nav>
