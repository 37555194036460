<lumiron-section class="header">
  <lumiron-section-body>
    <div class="left-col">
      <span class="location"><i class="bi bi-geo-alt"></i>Ostschweiz</span>
      <h1>BUBBLE SOCCER</h1>
      <p>
        Wir vermieten betreutes und unbetreutes Bubble Soccer in der Ostschweiz.
        Spass, Teamgeist und jede Menge Action inklusive. Jetzt buchen und das
        Spiel starten!
      </p>
      <div class="buttons">
        <lumiron-button-link [link]="'/buchung'"
          >Online buchen</lumiron-button-link
        >
        <lumiron-button-link [color]="'secondary'" [link]="'/angebot'"
          >Mehr erfahren</lumiron-button-link
        >
      </div>
    </div>
    <video muted autoplay loop playsinline>
      <source
        src="https://res.cloudinary.com/lumiron/video/upload/f_auto,q_auto,w_492/home-video-webm"
        type="video/webm"
      />

      <source
        src="https://res.cloudinary.com/lumiron/video/upload/f_auto,q_auto,w_492/home-video"
        type="video/mp4"
      />
    </video>
  </lumiron-section-body>
</lumiron-section>

<lumiron-section class="events">
  <lumiron-section-body>
    <p class="title">Bring dein Event auf das nächste Level:</p>
    <div class="slide-wrapper">
      <div class="start-shadow"></div>
      <div class="slide">
        <span class="event">Polteranlass</span>
        <span class="event">Teambuilding</span>
        <span class="event">Geburtstagsparty</span>
        <span class="event">Schulveranstaltung</span>
        <span class="event">Firmenfeiern</span>
        <span class="event">Vereinsausflug</span>
        <span class="event">Grümpelturnier</span>
        <span class="event">Familienausflug</span>
        <span class="event">Freizeit mit Freunden</span>

        <span class="event">Polteranlass</span>
        <span class="event">Teambuilding</span>
        <span class="event">Geburtstagsparty</span>
        <span class="event">Schulveranstaltung</span>
        <span class="event">Firmenfeiern</span>
        <span class="event">Vereinsausflug</span>
        <span class="event">Grümpelturnier</span>
        <span class="event">Familienausflug</span>
        <span class="event">Freizeit mit Freunden</span>
      </div>
      <div class="end-shadow"></div>
    </div>
  </lumiron-section-body>
</lumiron-section>

<lumiron-section class="offers" [backgroundImage]="'green-background'">
  <lumiron-section-header>
    <lumiron-section-title>Unser Angebot</lumiron-section-title>
    <lumiron-section-summary
      >Wir bieten betreutes und unbetreutes Bubble Soccer für Gross und Klein
      an.</lumiron-section-summary
    >
  </lumiron-section-header>

  <lumiron-section-body>
    <lumiron-card>
      <lumiron-card-header>
        <lumiron-card-title>Bubble Soccer</lumiron-card-title>
      </lumiron-card-header>
      <lumiron-card-body>
        <p>
          Bubble Soccer für Erwachsene und Teenager. Ideal für Teambuilding,
          Polteri, Firmenfeiern und andere Anlässe. Sportliche Herausforderung,
          viel Lachen und unvergessliche Momente garantiert!
        </p>

        <lumiron-list>
          <lumiron-list-item>Betreut und Unbetreut</lumiron-list-item>
          <lumiron-list-item>Ab 1.50m Körpergrösse</lumiron-list-item>
          <lumiron-list-item>20+ Personen möglich</lumiron-list-item>
          <lumiron-list-item>Ab 400.- CHF</lumiron-list-item>
        </lumiron-list>
      </lumiron-card-body>
      <lumiron-card-footer class="buttons">
        <lumiron-button-link [link]="'/buchung'"
          >Online buchen</lumiron-button-link
        >
        <lumiron-button-link
          [color]="'secondary'"
          [link]="'/angebot'"
          [scrollTo]="'betreut'"
          >Mehr erfahren</lumiron-button-link
        >
      </lumiron-card-footer>
    </lumiron-card>

    <lumiron-card>
      <lumiron-card-header>
        <lumiron-card-title>Bubble Soccer Kids</lumiron-card-title>
      </lumiron-card-header>
      <lumiron-card-body>
        <p>
          Bubble Soccer für die Kleinen. Ideal für Kindergeburtstage,
          Schulausflüge und andere Anlässe. Einfach Bubbles abholen, und den
          Spielspass beginnen!
        </p>

        <lumiron-list>
          <lumiron-list-item>Unbetreut</lumiron-list-item>
          <lumiron-list-item>Ab 1.20m bis 1.50m Körpergrösse</lumiron-list-item>
          <lumiron-list-item>20+ Kinder möglich</lumiron-list-item>
          <lumiron-list-item>Ab 350.- CHF</lumiron-list-item>
        </lumiron-list>
      </lumiron-card-body>
      <lumiron-card-footer class="buttons">
        <lumiron-button-link [link]="'/buchung'"
          >Online buchen</lumiron-button-link
        >
        <lumiron-button-link
          [color]="'secondary'"
          [link]="'/angebot'"
          [scrollTo]="'kinder'"
          >Mehr erfahren</lumiron-button-link
        >
      </lumiron-card-footer>
    </lumiron-card>
  </lumiron-section-body>
</lumiron-section>

<!-- TODO Replace image -->
<lumiron-section class="image">
  <lumiron-section-body>
    <img
      [ngSrc]="'bubbles-in-garden'"
      width="1080"
      height="720"
      sizes="93vw, (min-width: 1200px) 50vw"
      placeholder
    />
  </lumiron-section-body>
</lumiron-section>

<lumiron-section class="offer-types">
  <lumiron-section-header>
    <lumiron-section-title>Betreut und Unbetreut</lumiron-section-title>
    <lumiron-section-summary
      >Wir bieten sowohl betreutes, wie auch unbetreutes Bubble Soccer an. Aber
      was ist der Unterschied?</lumiron-section-summary
    >
  </lumiron-section-header>

  <lumiron-section-body>
    <lumiron-card>
      <lumiron-card-header>
        <lumiron-card-title>Betreut</lumiron-card-title>
      </lumiron-card-header>
      <lumiron-card-body>
        <p>
          Beim Betreuten Bubble Soccer organisieren wir alles für dich! Wir
          stellen den Platz zur Verfügung, pumpen die Bubbles auf und sorgen für
          die gesamte Vorbereitung. Du und deine Gäste können einfach kommen,
          spielen und Spass haben - ohne Aufwand.
        </p>
      </lumiron-card-body>
      <lumiron-card-footer>
        <lumiron-button-link
          [color]="'secondary'"
          [link]="'/angebot'"
          [scrollTo]="'betreut'"
          >Mehr erfahren</lumiron-button-link
        >
      </lumiron-card-footer>
    </lumiron-card>

    <lumiron-card>
      <lumiron-card-header>
        <lumiron-card-title>Unbetreut</lumiron-card-title>
      </lumiron-card-header>
      <lumiron-card-body>
        <p>
          Mit der Unbetreuten-Option holst du die Bubbles bei uns ab und
          organisierst alles selbst. Du wählst deinen eigenen Platz, pumpst die
          Bubbles auf und bringst sie nach dem Spielen zurück. Ideal für
          maximale Flexibilität und individuelle Planung.
        </p>
      </lumiron-card-body>
      <lumiron-card-footer>
        <lumiron-button-link
          [color]="'secondary'"
          [link]="'/angebot'"
          [scrollTo]="'unbetreut'"
          >Mehr erfahren</lumiron-button-link
        >
      </lumiron-card-footer>
    </lumiron-card>
  </lumiron-section-body>
</lumiron-section>

<!-- TODO Feedback Section -->
