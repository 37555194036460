/// <reference types="@angular/localize" />

import {
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { HomeComponent } from './app/home/home.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { FlatpickrModule } from 'angularx-flatpickr';
import { provideCloudinaryLoader, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { provideHttpClient } from '@angular/common/http';

const firebaseOptions = {
  apiKey: 'AIzaSyBYw7S79Av5X3_xhvWjIE8oLtC-kG8VJAI',
  authDomain: 'lumiron-bubblesoccer.firebaseapp.com',
  databaseURL:
    'https://lumiron-bubblesoccer-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'lumiron-bubblesoccer',
  storageBucket: 'lumiron-bubblesoccer.appspot.com',
  messagingSenderId: '990437382583',
  appId: '1:990437382583:web:5956b070c476eef3b97032',
  measurementId: 'G-G89Y7PD3RZ',
};

if (environment.production) {
  enableProdMode();
}

const resolveRouteTitle = (title: string) =>
  `${title} | Lumiron - Bubble Soccer`;

registerLocaleData(localeDe);
bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      [
        { path: '', pathMatch: 'full', redirectTo: 'home' },
        {
          path: 'home',
          title: resolveRouteTitle('Home'),
          component: HomeComponent,
        },
        {
          path: 'angebot',
          title: resolveRouteTitle('Angebot'),
          loadComponent: () =>
            import('./app/offer/offer.component').then(
              (mod) => mod.OfferComponent,
            ),
        },
        {
          path: 'buchung',
          title: resolveRouteTitle('Buchung'),
          loadComponent: () =>
            import('./app/booking/booking.component').then(
              (mod) => mod.BookingComponent,
            ),
        },
        {
          path: 'ueber-uns',
          title: resolveRouteTitle('Über uns'),
          loadComponent: () =>
            import('./app/about/about.component').then(
              (mod) => mod.AboutComponent,
            ),
        },
        {
          path: 'kontakt',
          title: resolveRouteTitle('Kontakt'),
          loadComponent: () =>
            import('./app/contact/contact.component').then(
              (mod) => mod.ContactComponent,
            ),
        },
        {
          path: 'impressum',
          title: resolveRouteTitle('Impressum'),
          loadComponent: () =>
            import('./app/imprint/imprint.component').then(
              (mod) => mod.ImprintComponent,
            ),
        },
        {
          path: 'agb',
          title: resolveRouteTitle('AGB'),
          loadComponent: () =>
            import('./app/agb/agb.component').then((mod) => mod.AgbComponent),
        },
        { path: '**', redirectTo: 'home' },
      ],
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
    ),
    { provide: FIREBASE_OPTIONS, useValue: firebaseOptions },
    { provide: LOCALE_ID, useValue: 'de-CH' },
    provideHttpClient(),
    provideCloudinaryLoader('https://res.cloudinary.com/lumiron'),
    provideExperimentalZonelessChangeDetection(),
    provideFirebaseApp(() => initializeApp(firebaseOptions)),
    provideFirestore(() => getFirestore()),
    importProvidersFrom(
      BrowserModule,
      FlatpickrModule.forRoot(),
      // provideAppCheck(() => initializeAppCheck(getApp(), {
      //   provider: new ReCaptchaV3Provider('6Lf0XbcfAAAAACMMNRs28Gu4gEDvSQ_M35Siq8gE'),
      //   isTokenAutoRefreshEnabled: true
      // })),
    ),
    provideAnimations(),
  ],
});
