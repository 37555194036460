<lumiron-section>
  <lumiron-section-body>
    <lumiron-card class="cta" [backgroundImage]="'grass-texture'">
      <lumiron-card-body>
        <h2>Lass die Bubbles rollen</h2>
        <p>Jetzt buchen und viel Action erleben</p>
        <lumiron-button-link [link]="'/buchung'"
          >Online buchen</lumiron-button-link
        >
      </lumiron-card-body>
    </lumiron-card>

    <lumiron-card class="contact">
      <lumiron-card-header>
        <lumiron-card-title>LUMIRON</lumiron-card-title>
        <lumiron-card-summary
          ><p>Das ultimative Erlebnis für dein Event.</p></lumiron-card-summary
        >
      </lumiron-card-header>
      <lumiron-card-footer>
        <lumiron-button-link [color]="'secondary'" [link]="'/kontakt'"
          >Kontakt</lumiron-button-link
        >
      </lumiron-card-footer>
    </lumiron-card>

    <lumiron-card class="sites">
      <lumiron-card-body>
        <div class="list">
          <span class="title">Angebot</span>
          <a
            [routerLink]="'/angebot'"
            [fragment]="'betreut'"
            [routerLinkActive]="'active'"
            >Betreut</a
          >
          <a
            [routerLink]="'/angebot'"
            [fragment]="'unbetreut'"
            [routerLinkActive]="'active'"
            >Unbetreut</a
          >
          <a
            [routerLink]="'/angebot'"
            [fragment]="'kinder'"
            [routerLinkActive]="'active'"
            >Kinder</a
          >
        </div>

        <div class="list">
          <span class="title">Seiten</span>
          <a>Ablauf Betreut</a>
          <a>Ablauf Unbetreut</a>
          <a [routerLink]="'/ueber-uns'" [routerLinkActive]="'active'"
            >Über uns</a
          >
        </div>

        <div class="list">
          <span class="title">Ressourcen</span>
          <a [routerLink]="'/kontakt'" [routerLinkActive]="'active'">Kontakt</a>
          <a [routerLink]="'/impressum'" [routerLinkActive]="'active'"
            >Impressum</a
          >
          <a [routerLink]="'/agb'" routerLinkActive="active">AGB</a>
        </div>

        <span class="copyright"
          >©2024 Lumiron KLG, alle Rechte vorbehalten.</span
        >

        <div class="socials">
          <a
            href="https://www.linkedin.com/company/lumironklg/"
            aria-label="LinkedIn öffnen"
            ><i class="bi bi-linkedin"></i
          ></a>
          <a
            href="https://www.instagram.com/lumiron.bubblesoccer/"
            target="_blank"
            aria-label="Instagram öffnen"
            ><i class="bi bi-instagram"></i
          ></a>
        </div>
      </lumiron-card-body>
    </lumiron-card>
  </lumiron-section-body>
</lumiron-section>
